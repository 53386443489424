document.addEventListener("DOMContentLoaded", () => {
    console.log("header script loaded");
    const leftWrapper = document.createElement("li");
    const rightWrapper = document.createElement("li");
    const leftWrapperContainer = document.createElement("ul");
    const rightWrapperContainer = document.createElement("ul");

    leftWrapper.classList.add("column-left-wrapper");
    rightWrapper.classList.add("column-right-wrapper");

    leftWrapperContainer.classList.add("items-container");
    rightWrapperContainer.classList.add("items-container");

    const nav = document.querySelector(
        ".custom-header__nav.main-nav .main-nav__list"
    );
    const items = nav.querySelectorAll(".menu-item");

    items.forEach((item) => {
        if (item.classList.contains("column-left")) {
            leftWrapperContainer.appendChild(item);
        } else if (item.classList.contains("column-right")) {
            rightWrapperContainer.appendChild(item);
        }
    });

    leftWrapper.appendChild(leftWrapperContainer);
    rightWrapper.appendChild(rightWrapperContainer);

    nav.appendChild(leftWrapper);
    nav.appendChild(rightWrapper);
});
